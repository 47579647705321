import React from "react";

function Footer() {
  return (
    <footer className="footer-container">
      <p> &copy; Luke Phillips 2022 </p>
    </footer>
  );
}

export default Footer;
